<template>
  <CCardBody>

    <alert-section
      :successAlertMessage="successAlertMessage"
      :dismissSecs="dismissSecs"
      :dismissSuccessAlert="dismissSuccessAlert"
      :errorAlertMessage="errorAlertMessage"
      :showErrorAlert="showErrorAlert"
    />

    <div v-if="loadedItems.length">
      <CDataTable
        striped
        hover
        :items="loadedItems"
        :fields="fields"
        :sorter="{ external: true, resetable: true }"
        :column-filter="{ external: true, lazy: true }"
        :itemsPerPageSelect="{
          external: true,
          values: [5, 15, 25, 50, 100, 150, 200, 250, 500],
        }"
        :items-per-page.sync="itemsPerPage"
        :loading="loading"
        :noItemsView="{
          noResults: 'No filtering results are available!',
          noItems: 'No bank transaction found!',
        }"
        @update:sorter-value="sorterValue"
        @pagination-change="paginationChange"
      >

        <!-- ID -->
        <template #id="{item}">
          <td>
            #{{ item.id }}
          </td>
        </template>

        <!-- Type -->
        <template #type="{item}">
          <td>
            <em>{{ item.type | ucfirst }}</em>
          </td>
        </template>

        <!-- TXN Count -->
        <template #txn_cnt="{item}">
          <td class="text-center">
            <CLink class="d-inline count bg-info" :to="{ name: getInvoiceRouteName(item), params: { txn_id: item.id } }">
              {{ item.txn_count }}
            </CLink>
          </td>
        </template>

        <!-- Amount -->
        <template #amount="{item}">
          <td class="text-center">
            <strong>{{ item.total_paid | toCurrency('remove_thousand_separator') }}</strong>
          </td>
        </template>

        <!-- Date Filter -->
        <template #date-filter>

          <div class="input-group mb-1">
            <div class="input-group-prepend">
              <span class="input-group-text p-1 w-min">From</span>
            </div>
            <input
              id="date-filter-from"
              type="date"
              v-model="filters.txn.startDate"
              class="w-min"
            />
          </div>

          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text p-1 w-min">To</span>
            </div>
            <input
              id="date-filter-from"
              type="date"
              v-model="filters.txn.endDate"
              class="w-min"
            />
          </div>
        </template>

        <!-- Date -->
        <template #date="{item}">
          <td>
            {{ new Date(item.created_at).toLocaleString() }}
          </td>
        </template>

        <!-- File -->
        <template #file="{item}">
          <td>
            <CLink @click="downloadTxnFile(item.file_name)">{{ item.file_name }}</CLink>
          </td>
        </template>

        <!-- Actions -->
        <template #actions="{item}">
          <td class="py-2 text-center">
            <CButton size="sm" color="info" shape="pill" v-c-tooltip="{ html: true, content: 'Invoices' }"
              :to="{ name: getInvoiceRouteName(item), params: { txn_id: item.id } }">
              <CIcon name="cil-spreadsheet" />
            </CButton>

            <CButton size="sm" color="primary" shape="pill" class="ml-1" v-c-tooltip="{ html: true, content: 'Download' }"
              @click="downloadTxnFile(item.file_name)">
              <CIcon name="cil-cloud-download" />
            </CButton>
          </td>
        </template>
      </CDataTable>

      <CCard class="actions sticky-bottom">
        <CCardBody class="p-2">
          <div class="d-flex flex-wrap align-items-center" style="gap: 0.75rem">
            <div>
              <h5 class="mt-1">Total: <span class="d-inline count bg-primary pb-1">{{ total }}</span></h5>
            </div>

            <div v-if="pages > 1" class="ml-auto">
              <CPagination
                align="center"
                :dots='false'
                :pages="pages"
                :active-page.sync="activePage"
              />
            </div>
          </div>
        </CCardBody>
      </CCard>
    </div>

    <div v-else>
      <CSpinner v-if="loading" color="primary" />
      <h6 v-else>No bank transaction found!</h6>
    </div>

    <mc-spinner :opacity="0.8" v-show="submitted" :mtop="(32 + mcSpinnerMarginTop) + 'px'" />
  </CCardBody>
</template>

<script>
import { mapState } from 'vuex'
import _debounce from 'lodash/debounce';

export default {
  name: "BackendTable",
  components: {
  },
  data() {
    return {
      loadedItems: [],
      fields: [
        { key: "actions", label: "",      filter: false, sorter: false, _style: "min-width: 100px; width: 100px; text-align: center;" },
        { key: "id",      label: "ID",    filter: false, _style: "min-width: 50px; width: 75px;" },
        { key: "type",    filter: false,  _style: "min-width: 100px; width: 100px;" },
        { key: "txn_cnt", label: "TXN Count", filter: false,  _style: "min-width: 100px; width: 100px; text-align: center;" },
        { key: "amount",  filter: false,  _style: "min-width: 100px; width: 140px; text-align: center;" },

        { key: "date",    filter: true,   _style: "min-width: 160px; width: 240px;" },
        { key: "file",    filter: false,  _style: "min-width: 240px;" },
      ],

      activePage: 1,
      pages: 1,
      total: 0,
      items_per_page: null,
      orderBy: null,
      direction: null,
      search: null,
      filteredColumn: null,

      loading: false,
      submitted: false,

      // Alert işlemleri için
      successAlertMessage: {},
      dismissSecs: 10,
      dismissSuccessAlert: 0,
      errorAlertMessage: {},
      showErrorAlert: false,

      // Element Cover Spinner
      mcSpinnerMarginTop: 0,
    };
  },

  async beforeMount() {
    this.filterByDate = _debounce(async () => {
      await this.getAllItems();
    }, 1000);
  },

  async mounted() {
    this.activePage = this.queryPage;
    await this.getAllItems();

    window.onscroll = () => {
      this.mcSpinnerMarginTop = document.documentElement.scrollTop;
    }
  },

  watch: {
    reloadParams() {
      if(this.queryPage != this.activePage) {
        this.$router.push({ name: this.$route.name, query: { page: this.activePage } });
      }
      this.onTableChange();
    },
    dateFilter() {
      this.filterByDate();
    }
  },

  computed: {
    ...mapState(['filters']),

    itemsPerPage: {
      get: function () { return this.items_per_page ? this.items_per_page : parseInt(process.env.VUE_APP_ITEMS_PER_PAGE) },
      set: function (newValue) { this.items_per_page = newValue }
    },
    reloadParams() { return [this.activePage] },
    queryPage() { return parseInt(this.$route.query.page) || 1 },
    dateFilter() { return [this.filters.txn.startDate, this.filters.txn.endDate] }
  },

  methods: {
    async getAllItems() {
      this.loading = true;

      var url = new URL(this.$backend.BANK_TRANSACTIONS.GET_ALL),
        params = {
          page: this.activePage,
          itemsPerPage: this.itemsPerPage,
          'order-by': this.orderBy,
          direction: this.direction,
          date_from: this.filters.txn.startDate,
          date_to: this.filters.txn.endDate
        };

      Object.keys(params).forEach((key) => {
        if (
          typeof params[key] !== "undefined" &&
          params[key] !== null &&
          params[key] !== "" &&
          params[key].length !== 0
        )
          url.searchParams.append(key, params[key]);
      });

      await this.$axios.get(url)
        .then((response) => {
          return response.data;
        })
        .then((items) => {
          // Son sayfadan daha büyük bir queryPage çağrımı (?page=XXX) yapılırsa;
          if(items.current_page > items.last_page)
            this.activePage = 1;
          else {
            this.loadedItems = items.data;
            this.activePage = items.current_page;
            this.pages = items.last_page;
            this.total = items.total;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async downloadTxnFile(fileName) {
      this.submitted = true;

      await this.$axios
        .get(this.$backend.BANK_TRANSACTIONS.DOWNLOAD.replace("{file_name}", fileName), { responseType: 'blob' })
        .then((response) => {
          // Download file from Blob
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName); //or any other extension
          document.body.appendChild(link);
          link.click();
          link.remove();
          this.$toast.success(`Bank transaction file downloaded.`);
        })
        .catch(() => {
          this.$toast.error(`File not found!`);
        })
        .finally(() => {
          this.submitted = false;
        });
    },

    onTableChange() { // Active Page güncellendiğinde çağrılıyor.
      this.getAllItems();
    },
    paginationChange(value) { // Items Per Page güncellendiğinde çağrılıyor.
      this.itemsPerPage = value;
      this.getAllItems();
    },
    sorterValue(item) {
      this.orderBy = item.column;
      this.direction = item.column ? (item.asc ? "asc" : "desc") : null;
      this.getAllItems();
    },

    getInvoiceRouteName(item) {
      return this.extractTransactionText(item.file_name) == "courier"
        ? 'CourierInvoicesByTxn'
        : 'InvoiceListByTxn';
    },
    extractTransactionText(fileName) {
      const match = fileName.match(/menute-(.+?)-sepa/);
      return match ? match[1].replace(/-/g, '') === 'courier' ? 'courier' : null : null;

      // extractTransactionText('menute-courier-sepa-2024-10-25-02-08-49.xml') -> "courier"
      // extractTransactionText('menute-sepa-2024-07-31-01-14-26.xml') -> null
    }
  },
};
</script>

<style>
.table th, .table td {
  padding: 0.5rem 0.25rem;
  vertical-align: middle;
}
@media (min-width: 576px) {
  input[aria-label='table filter input'] {
    width: 280px !important;
  }
}
</style>

<style scoped>
/* Date Filter */
span.w-min {
  width: 48px;
}
input.w-min {
  width: 100px;
}
input[type="date" i],
input[type="datetime-local" i] {
  color: #f9b115;
}
input[type="date"]::-webkit-calendar-picker-indicator,
input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  padding-left: 4px;
  margin: 0;
}
</style>
